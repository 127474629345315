@use "sass:map";
@import "../../../styles/fonts-weights";
@import "../../../styles/sizes";
@import '../../../media';
@import "../../../styles/colors";

.reviewsContainer {
  margin: $small-desktop-default-gap*5 0 $small-desktop-default-gap*5 $small-desktop-carousel-container-side-margin;
  display:flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
  text-align: center;
}

.reviewsTitle {
  text-align: center;
  margin-bottom: $small-desktop-default-gap;
}

.reviewsText {
  text-align: center;
  margin-bottom: $small-desktop-default-gap*2;
  font-weight: map.get($font-weights, "boldText");
  padding: 0 $small-desktop-app-container-side-padding;
}

.reviewContainer {
  width: 280px;
  display:inline-block;
}

.review {
  width: 280px;
  min-height: 480px;
  padding: $small-desktop-default-gap;
  display: flex;
  flex-direction: column;

  &>.reviewerPhoto{
    width: 110px;
    border-radius: 5px;
    //border: 1px solid $main-red-color;
  }

  &>.rate {
    margin-top: $small-desktop-default-gap*2;
    width:40%;
  }

  &> p {
    margin-top: $small-desktop-default-gap;
    font-weight: map.get($font-weights, "boldText");
    text-align: left;
    font-size: map.get($smallDesktopFontSizes,"smallText");
  }
  &>h6 {
    margin-top: $small-desktop-default-gap;
    font-weight: map.get($font-weights, "title");
    font-size: map.get($smallDesktopFontSizes,"header");
    text-align: left;
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .reviewsContainer {
    margin: $small-desktop-default-gap*5 0 $small-desktop-default-gap*5 $small-desktop-carousel-container-side-margin;
  }

  .reviewsTitle {
    margin-bottom: $small-desktop-default-gap;
  }

  .reviewsText {
    padding: 0 $small-desktop-app-container-side-padding;
    margin-bottom: $small-desktop-default-gap*2;
  }

  .reviewContainer {
    width: 260px;
  }

  .review {
    width: inherit;
    min-height: 520px;
    padding: $small-desktop-default-gap;

    &>.reviewerPhoto{
      width: 110px;
    }

    &>.rate {
      margin-top: $small-desktop-default-gap*2;
      width:40%;
    }

    &> p {
      font-size: map.get($smallDesktopFontSizes,"smallText");
      margin-top: $small-desktop-default-gap;
    }
    &>h6 {
      margin-top: $small-desktop-default-gap;
      font-size: map.get($smallDesktopFontSizes,"header");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .reviewsContainer {
    margin: $small-desktop-default-gap*5 0 $small-desktop-default-gap*5 $small-desktop-carousel-container-side-margin;
  }

  .reviewsTitle {
    margin-bottom: $small-desktop-default-gap;
  }

  .reviewsText {
    padding: 0 $small-desktop-app-container-side-padding;
    margin-bottom: $small-desktop-default-gap*2;
  }

  .reviewContainer {
    width: 280px;
  }

  .review {
    width: inherit;
    min-height: 480px;
    padding: $small-desktop-default-gap;

    &>.reviewerPhoto{
      width: 110px;
    }

    &>.rate {
      margin-top: $small-desktop-default-gap*2;
      width:40%;
    }

    &> p {
      margin-top: $small-desktop-default-gap;
      font-size: map.get($smallDesktopFontSizes,"smallText");
    }

    &>h6 {
      margin-top: $small-desktop-default-gap;
      font-size: map.get($smallDesktopFontSizes,"header");
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .reviewsContainer {
    margin: $desktop-default-gap*5 0 $desktop-default-gap*5 $desktop-carousel-container-side-margin;
  }

  .reviewsTitle {
    margin-bottom: $desktop-default-gap;
  }

  .reviewsText {
    padding: 0 $small-desktop-app-container-side-padding;
    margin-bottom: $desktop-default-gap*2;
  }

  .reviewContainer {
    width: 335px;
  }

  .review {
    width: inherit;
    min-height: 600px;
    padding: $desktop-default-gap;

    &>.reviewerPhoto{
      width: 130px;
    }

    &>.rate {
      margin-top: $desktop-default-gap*2;
      width:40%;
    }

    &> p {
      margin-top: $desktop-default-gap;
      font-size: map.get($smallDesktopFontSizes,"smallText");
    }
    &>h6 {
      margin-top: $desktop-default-gap;
      font-size: map.get($desktopFontSizes,"header");
    }
  }
}


