@use "sass:map";
@import "../../../styles/sizes";
@import '../../../media';

.footer{
  text-align: center;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {

}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {

}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height)  {

}

