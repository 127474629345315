@import "src/styles/colors";

.dark{
  .special-order__option__most-popular{
    background-color: $main-red-color;
    color: $main-black-color;
    border-radius: 8px 8px 0 0;
  }
  .special-order__timer__container{
    color: $main-white-color;
    .timerCountdown{
      color: $main-white-color;
    }
  }
}
.special-order{
  &__timer{
    border-radius: 6px;
    color: $main-black-color;
    background-color: #FFFFFF12;
    padding: 16px 0;
    .timerCountdown{
      color: $main-black-color;
      font-size: 24px;
    }
    &__container{
      gap: 4px;
    }
  }
  .payCardsContainer{
    width: fit-content !important;
  }
  &__footer-text{
    text-align: center;
    row-gap: 12px;
  }
  &__option{
    border: 1px solid #E2E0E0;
    border-radius: 10px;
    position: relative;
    &.popular{
      box-shadow: 4px 6px 0 0 $main-red-color;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: $main-red-color;
      border-left-color: $main-red-color;
    }
    &__most-popular{
      padding: 16px 0;
      background-color: $main-red-color;
      color: $main-white-color;
      border-radius: 8px 8px 0 0;
    }

  &__wrapper{
    padding: 16px;
    &__plan {
      &__left {
        &__bottom {
          padding-left: 32px;
          margin-top: 12px;

          &__old {
            text-decoration: line-through;
          }
        }

      }

      &__right {
        align-items: flex-end;
        color: #5D5D5D;

        &__sales {
          text-decoration: line-through;
        }

        &__per-day {
          border-radius: 6px;
          background: #FFFFFF12;
          padding: 6px 14px;
        }
      }
    }
  }
  }
}

.light{
  .special-order__timer{
    background-color: rgba(1, 1, 1, 0.0705882353);
  }
  .special-order__option__wrapper__plan__right__per-day{
    background-color: rgba(1, 1, 1, 0.0705882353);
  }
}