@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";

.payInfoContainer {
  margin-top:$small-desktop-default-gap*6;
  text-align: center;
}

.discountAmount {
  color:$main-red-color;
  font-size: map.get($smallDesktopFontSizes,"imageTitle");
}

.priceInfoContainer {
 margin-top: 5px;
  font-weight: map.get($font-weights, "boldText");
}

.oldPrice {
  text-decoration: line-through;
  text-decoration-color: red;
}

.discountPrice{
  font-size: map.get($smallDesktopFontSizes,"title");
  font-weight: map.get($font-weights, "title");
  line-height: map.get($smallDesktopLineHeights, "title");
}

.priceCondition {
  margin-bottom: 10px;
  font-size: map.get($smallDesktopFontSizes, "info");
}

.payInfoTryButton{
  margin: $small-desktop-default-gap 0 $small-desktop-default-gap/2;
}
.secondary-payment-text{
  color: #A2A2A2;
}

.payInfoContainerTimer {
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 5px;
}

.timerText {
  font-size: map.get($smallDesktopFontSizes,"text");
  text-align: center;
}

.payInfoTimerDesign {
  display: flex;
  align-items: center;
  text-align: start;
  font-size: map.get($smallDesktopFontSizes,"text");
}

.payInfoConditionsContainer {
  margin-top: $small-desktop-default-gap;

  &>p,span {
    text-align: center;
    font-size: map.get($smallDesktopFontSizes, "info");
    line-height: map.get($smallDesktopLineHeights, "smallText");
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .payInfoContainer {
    margin-top:$small-desktop-default-gap*6;
  }

  .priceInfoContainer {
    margin-top: 5px;
  }

  .discountAmount {
    font-size: map.get($smallDesktopFontSizes,"imageTitle");
  }

  .discountPrice{
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights, "title");
  }

  .priceCondition {
    font-size: map.get($smallDesktopFontSizes, "info");
  }

  .payInfoTryButton{
    margin: $small-desktop-default-gap 0 $small-desktop-default-gap/2;
  }

  .timerText {
    font-size: map.get($smallDesktopFontSizes,"text");
  }

  .payInfoTimerDesign {
    font-size: map.get($smallDesktopFontSizes,"text");
  }

  .payInfoConditionsContainer {
    margin-top: $small-desktop-default-gap;

    &>p,span {
      font-size: map.get($smallDesktopFontSizes, "info");
      line-height: map.get($smallDesktopLineHeights, "smallText");
    }
  }

  .payInfoContainerTimer {
    grid-template-columns: 60% 40%;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .payInfoContainer {
    margin-top:$small-desktop-default-gap*6;
  }

  .priceInfoContainer {
    margin-top: 5px;
  }

  .discountAmount {
    font-size: map.get($smallDesktopFontSizes,"imageTitle");
  }

  .discountPrice{
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights, "title");
  }

  .priceCondition {
    font-size: map.get($smallDesktopFontSizes, "info");
  }

  .payInfoTryButton{
    margin: $small-desktop-default-gap 0 $small-desktop-default-gap/2;
  }

  .timerText {
    font-size: map.get($smallDesktopFontSizes,"text");
  }

  .payInfoTimerDesign {
    font-size: map.get($smallDesktopFontSizes,"text");
  }

  .payInfoConditionsContainer {
    margin-top: $small-desktop-default-gap;

    &>p,span {
      font-size: map.get($smallDesktopFontSizes, "info");
      line-height: map.get($smallDesktopLineHeights, "smallText");
    }
  }

  .payInfoContainerTimer {
    grid-template-columns: 60% 40%;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .payInfoContainer {
    margin-top:$desktop-default-gap*6;
  }

  .priceInfoContainer {
    margin-top: 10px;
  }

  .discountAmount {
    font-size: map.get($desktopFontSizes,"imageTitle");
  }

  .discountPrice{
    font-size: map.get($desktopFontSizes,"title");
    line-height: map.get($desktopLineHeights, "title");
  }

  .priceCondition {
    font-size: map.get($desktopFontSizes, "info");
  }

  .payInfoTryButton{
    margin: $desktop-default-gap 0 $desktop-default-gap/2;
  }

  .timerText {
    font-size: map.get($desktopFontSizes,"text");
  }

  .payInfoTimerDesign {
    font-size: map.get($desktopFontSizes,"text");
  }

  .payInfoConditionsContainer {
    margin-top: $desktop-default-gap;

    &>p,span {
      font-size: map.get($desktopFontSizes, "info");
      line-height: map.get($desktopLineHeights, "smallText");
    }
  }

  .payInfoContainerTimer {
    grid-template-columns: 57% 43%;
  }
}

.payment-modal{
  width: 100%;
  max-width: 532px;
  margin: auto;
  padding: 56px 40px 48px;
  background-color: $dark-theme;
  max-height: 932px;
  height: 100%;
  overflow: scroll;
  //border-radius: 25px;
  .payment-modal-content{
    &__header-icon{
      display: flex;
      justify-content: flex-end;
    }
    &__header-title{
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      color: $light-theme;
    }
    &__total{
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      margin-bottom: 48px;
      &__name{
        color: $light-theme;
      }
      &__price{
        color: $main-red-color;
      }
      &__save{
        color: #A2A2A2;
      }
    }
    &__footer-icon{
      text-align: center;
      margin: 24px 0 16px;
    }
    &__footer-weekly{
      border-bottom: 1px solid #D1D1D1;
      padding-bottom: 40px;
      .total-price-wrapper{
        color: $main-black-color;
      }

    }
    &__footer-text{
      font-size:9px;
      line-height: 11px;
      opacity: 60%;
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 16px;
      color: $light-theme;
    }
  }
  .form-group{
    margin: 12px 0;
  }
  .two-in-the-row{
    display: flex;
    gap: 4px;
    justify-content: space-between;
    .basic-select{
      width: 142px;
    }
    .form-group{
      width: 142px;
    }
  }
  .basic-select{
    margin-top: 12px;
    .select__menu{
      background-color: $dark-theme;
      border: 1px solid $dark-theme-border-color;
      .select__menu-list{
        color: $dark-theme-text;
      }
      .select__option--is-focused{
        background-color: $dark-theme;
      }
      .select__option--is-selected{
        background-color: $main-red-color;
      }
    }
    .select__control{
      border-radius: 12px;
      height: 45px;
      border-color: $dark-theme-border-color;
      color: $main-white-color;
      background-color: $dark-theme;
      &.select__control--is-focused{
        box-shadow: $input-focus-box-shadow;
        border-color: initial;
      }
      .select__placeholder{
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: $dark-theme-border-color;
      }
      .select__input-container{
        color: $dark-theme-text;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
      }
      .select__single-value {
        color: $dark-theme-text;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
      }

    }
    .select__indicator-separator{
      display: none;
    }
    .select__indicator{
      svg{
        path{
          fill: $dark-theme-border-color;
        }
      }
    }
  }
}
  .payment-modal{
    &.light{
      box-shadow: 0 0 25px 0 #0000001A;
      background-color: $light-theme;
      .payment-modal-content__footer-text, .payment-modal-content__header-title, .payment-modal-content__total__name{
        color: $main-black-color;
      }
      .basic-select{
        .select__control{
          background-color: $light-theme;
          color: $input-border-color;
          border-color: rgba(23, 23, 23, 0.5);
          .select__placeholder{
            color: $dark-theme-border-color;
          }
          .select__input-container{
            color: $input-border-color;
          }
          .select__single-value {
            color: $input-border-color;
          }
          .select__placeholder{
            color: $input-border-color;
          }
        }
        .select__menu{
          background-color: $light-theme;
          border: 1px solid $input-border-color;
          .select__menu-list{
            color: $input-border-color;
          }
          .select__option--is-focused{
            background-color: $light-theme;
          }
          .select__option--is-selected{
            background-color: $main-red-color;
          }
        }
      }
    }

  }

.dark{
  .payment-modal-content__footer-weekly{
    .total-price-wrapper{
      color: $main-white-color;
    }

  }
}