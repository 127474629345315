@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/colors";
@import "../../../styles/line-heights";
@import '../../../media';

.testBeginningTitle {
  text-align: left;
  //color: $main-red-color;

  margin-top: $small-desktop-default-gap;
  font-size: $small-desktop-test-beginning-title-font-size;
  line-height: $small-desktop-test-beginning-title-line-height;
}

.testBeginningText {
  text-align: left;
  margin-top: $small-desktop-default-gap;
}

.continueButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .testBeginningTitle {
    margin-top: $small-desktop-default-gap;
    font-size: $small-desktop-test-beginning-title-font-size;
    line-height: $small-desktop-test-beginning-title-line-height;
  }

  .testBeginningText {
    margin-top: $small-desktop-default-gap;
  }

  .continueButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .testBeginningTitle {
    margin-top: $small-desktop-default-gap;
    font-size: $small-desktop-test-beginning-title-font-size;
    line-height: $small-desktop-test-beginning-title-line-height;
  }

  .testBeginningText {
    margin-top: $small-desktop-default-gap;
  }

  .continueButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .testBeginningTitle {
    margin-top: $desktop-default-gap*2;
    //font-size: map.get($desktopFontSizes,'title');
    //line-height: map.get($desktopLineHeights,'title');
  }

  .testBeginningText {
    margin-top: $desktop-default-gap;
  }

  .continueButton {
    margin-top: $desktop-default-gap;
  }
}
