@import "src/styles/sizes";
@import "src/styles/colors";
@import "src/media";

.dark{
.main-item-wrapper{
  background-color: $main-black-color;
}
  .main-item-wrapper__content{
    color: $main-white-color !important;
  }
  .main-content__title-count{
    color: $main-white-color;
  }
  .main-item-wrapper__progress-container__bar{
    background-color: $dark-theme-bg-bar;
  }
  .main-item-wrapper__subtitle{
    opacity: 60%;
  }
}
.main-content{
  &__header-wrapper{
    padding: 0 24px;
    margin-bottom: 25px;
    align-items: baseline;
  }
  &__title{
    color: $main-red-color;
    font-family: 'Bebas Neue', sans-serif;
  }
  &__title-count{
    color: $main-black-color;
    span{
      color: $main-red-color;
    }
  }
  div{
    &:before{
      display: none;
    }
    &:after{
      display: none;
    }
  }
}
.main-item-wrapper{
  border-radius: 8px;
  background-color: $light-gray;
  max-width: 100%;
  margin: 0 8px;
  position: relative;
  @media screen and (max-width: $smartphone-width) {
    max-width: calc(100dvw - 24px - 24px);
  }

  &__favorite{
    position: absolute;
    top: 12px;
    right: 12px;
    &.active{
      path{
        fill: $main-red-color;
      }
    }
  }

  &__content{
    padding: 20px 8px;
  }
  &__title{
    //color: $main-black-color;
    font-family: 'Bebas Neue', sans-serif;
  }
  &__progress-container{
    padding: 20px 16px;
    row-gap: 10px;
    height: 100%;
    justify-content: flex-end;
    &__bar{
      width: 100%;
      position: relative;
      border-radius: 2px;
      height: 6px;
      background-color: $dark-theme-bg-bar-4;
      &__red{
        position: absolute;
        border-radius: 2px;
        top: 0;
        height: 6px;
        left: 0;
        background-color: $main-red-color;
      }
    }
  }
  &__subtitle{
    //color: $subtitle-color;
    line-height: 140% !important;
  }
  img{
    width: 100%;
    max-width: calc(100dvh - 48px - 48px);
    object-fit: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
  }
}

.styles-module_sliderBase__swkx1 {
  .main-item-wrapper{
    max-width: calc(535px - 48px - 48px - 12px);
  }
}