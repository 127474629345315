@import 'src/styles/colors';
.header-container{
  padding: 32px 24px 0;
  position: static;
  top: 0;
  div, span{
    font-family: 'Bebas Neue', sans-serif;
  }
  &__group{
    gap: 12px;
  }
  &:before{
    display: none;
  }
  &:after{
    display: none;
  }
  #profile-icon-header{
    &:hover{
      border: 1px solid  $main-red-color;
      border-radius: 50%;
    }
  }
  #home-icon-header{
    &:hover{
      rect{
        &:nth-of-type(2){
          stroke: $main-red-color;
          stroke-opacity: 1;
        }
      }
    }
  }
}

button{
  font-family: 'Bebas Neue', sans-serif;
}