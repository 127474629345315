@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import "../../../styles/line-heights";
@import "../../../styles/sizes";
@import '../../../media';

.emailCheckTitle {
  text-align: left;
  text-transform: uppercase;
  font-weight: map.get($font-weights,"text");
  margin-bottom: $small-desktop-default-gap;
}

.emailForm {
  width:100%;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .emailCheckTitle {
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights,"title");
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .emailCheckTitle {
    font-size: map.get($smallDesktopFontSizes,"title");
    line-height: map.get($smallDesktopLineHeights,"title");
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .emailCheckTitle {
    font-size: map.get($desktopFontSizes,"title");
    line-height: map.get($desktopLineHeights,"title");
    margin-bottom: 0;
  }
}
