@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';
@import "../../../styles/colors";
@import "../../../styles/line-heights";
@import "../../../styles/themeVariables";

.payInfoPage {
  display:flex;
  flex-direction: column;
}

.timer{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.timerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timerText {
  font-size: map.get($smallDesktopFontSizes,"smallText");
  font-weight: map.get($font-weights, "title");
  text-align: center;
}

.pulseButton {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 2s infinite;

  &:hover {
    -webkit-animation: none;
  }
}

.headerTimerDesign {
  color: $main-red-color;
  font-size: map.get($smallDesktopFontSizes,"header");
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $main-red-color;
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.guaranteeText {
  text-align: center;
  font-size: map.get($desktopFontSizes, "info");
  line-height: map.get($smallDesktopLineHeights, "smallText");
  margin-top:$small-desktop-default-gap;
}


@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .timerText {
    font-size: map.get($smallDesktopFontSizes,"smallText");
  }

  .guaranteeText {
    margin-top:$small-desktop-default-gap;
  }

  .headerTimerDesign {
    font-size: map.get($smallDesktopFontSizes,"header");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .timerText {
    font-size: map.get($smallDesktopFontSizes,"smallText");
  }

  .guaranteeText {
    margin-top:$small-desktop-default-gap;
  }

  .headerTimerDesign {
    font-size: map.get($smallDesktopFontSizes,"header");
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .timerText {
    font-size: map.get($desktopFontSizes,"smallText");
  }

  .guaranteeText {
    margin-top:$desktop-default-gap;
  }

  .headerTimerDesign {
    font-size: map.get($desktopFontSizes,"header");
  }
}
.timer-sticky{
  padding:$small-desktop-default-gap $small-desktop-app-container-side-padding;
  position: sticky;
  top: 0;
  z-index: 12;
  margin-left: -$small-desktop-app-container-side-padding;
  width: calc($small-desktop-app-container-width);
  @media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
    min-width: calc($mobile-app-container-width);
    padding:$small-desktop-default-gap $small-desktop-app-container-side-padding;
    margin-left: -$small-desktop-app-container-side-padding;
  }
  @media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
    width: calc($small-desktop-app-container-width);
    padding:$small-desktop-default-gap $small-desktop-app-container-side-padding;
    margin-left: -$small-desktop-app-container-side-padding;
  }
  @media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
    width: calc($desktop-app-container-width);
    padding:$small-desktop-default-gap $desktop-app-container-side-padding;
    margin-left: -$desktop-app-container-side-padding;
  }
}
.light{
  .timer-sticky{
    background-color: $light-theme;
  }
}
.dark{
  .timer-sticky{
    background-color: $dark-theme;
  }
}

.gift-modal{
  height: 352px !important;
  border-radius: 20px;
  max-width: 500px!important;
  transform: translateY(50%)!important;
  padding-top: 24px!important;
.gift-text{
  color: $main-white-color;
}
  &.light{
    .gift-text{
      color: $main-black-color;
    }
    svg{
      path{
        &:nth-of-type(6){
          fill: #E2E2E2;
        }
        &:nth-of-type(8){
          fill: #E2E2E2;
        }
        &:nth-of-type(9){
          fill: #E2E2E2;
        }
        &:nth-of-type(10){
          fill: #E2E2E2;
        }
      }
    }
  }
}

.gift-svg {
  svg {
    width: 208px;
    height: 208px;
  }
}
.light{
.gift-svg {
  svg {
    path {
      &:nth-of-type(6) {
        fill: #E2E2E2;
      }

      &:nth-of-type(8) {
        fill: #E2E2E2;
      }

      &:nth-of-type(9) {
        fill: #E2E2E2;
      }

      &:nth-of-type(10) {
        fill: #E2E2E2;
      }
    }
  }
}

}