@use "sass:map";
@import 'src/styles/colors';
@import 'src/styles/themeVariables';

.dark{
  .child-container__games-wrapper{
    background-color: $main-black-color;
  }
}

.child-container{
  padding: 32px 24px 180px;
  &.without-left-padding{
    padding-right: 0;
  }
  &__title{
    text-decoration-color: map.get($lightTheme, "colorText") ;
  }
  &__red-title{
    //color: $main-red-color;
  }
  &__list{
    ul{
      padding-left: 20px;
    }
  }
  &__games-wrapper{
    border-radius: 0px 0px 8px 8px;
    background-color: $light-gray;
    row-gap: 12px;
    padding: 12px 16px;
    .icon {
      background-color: $main-red-color;
      border-radius: 50%;
      padding: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: 30px;
      max-width: 30px;
    }
    .icon:after {
      content: '';
      display: block;
      height: 0;
      width: 100%;
      max-height: 30px;
      max-width: 30px;
      padding-bottom: 100%;
    }
    .child-container__red-title font-bebas{
      color: $main-white-color;
    }
  }
  &__note{
    padding-left: 12px;
    border-left: 1.5px solid $main-red-color;
  }
  &__image{
    width: inherit;
    border-radius: 5px;
    img{
      width: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      border-radius: 5px;
    }
  }
}

.padding-right-24{
  padding-right: 24px;
}