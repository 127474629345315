@use "sass:map";
@import "../../../styles/sizes";
@import '../../../media';

.ageCheckContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ageGapContainer{
  width: 100%;
  margin-bottom: 20px;
}

.ageCheckInfo{
  font-size: 20px;
  margin: $small-desktop-default-gap 0 0;
}

.ageGapButton{
  margin: $small-desktop-default-gap 0 0;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height){
  .ageCheckInfo{
    margin: $small-desktop-default-gap 0 0;
  }

  .ageGapButton{
    margin: $small-desktop-default-gap 0 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .ageCheckInfo{
    margin: $small-desktop-default-gap 0 0;
  }

  .ageGapButton{
    margin: $small-desktop-default-gap 0 0;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .ageCheckInfo{
    margin: $desktop-default-gap 0 0;
  }

  .ageGapButton{
    margin: $desktop-default-gap 0 0;
  }
}


