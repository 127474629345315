@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.educationTimeCheckQuestion {
  text-align: left;
  text-transform: uppercase;
  margin: $small-desktop-default-gap 0;
}

.educationTimeOptionButton {
  margin-top: $small-desktop-default-gap;

  &:first-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .educationTimeCheckQuestion {
    margin: $small-desktop-default-gap 0;
  }

  .educationTimeOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .educationTimeCheckQuestion {
    margin: $small-desktop-default-gap 0;
  }

  .educationTimeOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .educationTimeCheckQuestion {
    margin: $desktop-default-gap*2 0;
  }

  .educationTimeOptionButton {
    margin-top: $desktop-default-gap;
  }
}

