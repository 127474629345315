@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.honestCheckTitle {
  text-align: left;
  margin-top: $small-desktop-default-gap;
}

.honestCheckText {
  text-align: left;
  margin-top: $small-desktop-default-gap;
}

.honestCheckQuestion {
  text-align: left;
  width: 100%;
  font-weight: map.get($font-weights,"title");
  margin-top: $small-desktop-default-gap;
}

.honestCheckAgreeButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .honestCheckTitle {
    margin-top: $small-desktop-default-gap;
  }

  .honestCheckText {
    margin-top: $small-desktop-default-gap;
  }

  .honestCheckQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .honestCheckAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .honestCheckTitle {
    margin-top: $small-desktop-default-gap;
  }

  .honestCheckText {
    margin-top: $small-desktop-default-gap;
  }

  .honestCheckQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .honestCheckAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .honestCheckTitle {
    margin-top: $desktop-default-gap;
  }

  .honestCheckText {
    margin-top: $desktop-default-gap;
  }

  .honestCheckQuestion {
    margin-top: $desktop-default-gap;
  }

  .honestCheckAgreeButton {
    margin-top: $desktop-default-gap;
  }
}


