@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.candorCheckTitle {
  text-align: left;
  width: 100%;
  text-transform: uppercase;
  margin-top: $small-desktop-default-gap;
}

.candorCheckText {
  text-align: left;
  width: 100%;
  margin-top: $small-desktop-default-gap;
}

.candorCheckAgreeButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .candorCheckTitle {
    margin-top: $small-desktop-default-gap;
  }

  .candorCheckText {
    margin-top: $small-desktop-default-gap;
  }

  .candorCheckAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .candorCheckTitle {
    margin-top: $small-desktop-default-gap;
  }

  .candorCheckText {
    margin-top: $small-desktop-default-gap;
  }

  .candorCheckAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .candorCheckTitle {
    margin-top: $desktop-default-gap*2;
  }

  .candorCheckText {
    margin-top: $desktop-default-gap;
  }

  .candorCheckAgreeButton {
    margin-top: $desktop-default-gap*2;
  }
}
