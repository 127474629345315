@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/fonts-weights";
@import '../../../media';

.partnerOpennessTitle {
  text-align: left;
  text-transform: uppercase;
  margin-top: $small-desktop-default-gap;
}

.partnerOpennessText {
  text-align: left;
  margin-top: $small-desktop-default-gap;
}

.partnerOpennessQuestion {
  text-align: left;
  font-weight: map.get($font-weights,"title");
  margin-top: $small-desktop-default-gap;
}

.partnerOpennessOptionButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
   .partnerOpennessText {
    margin-top: $small-desktop-default-gap;
  }

  .partnerOpennessQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .partnerOpennessOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .partnerOpennessText {
    margin-top: $small-desktop-default-gap;
  }

  .partnerOpennessQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .partnerOpennessOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .partnerOpennessText {
    margin-top: $desktop-default-gap;
  }

  .partnerOpennessQuestion {
    margin-top: $desktop-default-gap;
  }

  .partnerOpennessOptionButton {
    margin-top: $desktop-default-gap;
  }
}


