@use "sass:map";
@import "/src/styles/colors";
@import "/src/styles/sizes";

.divisionContainer {
  display:flex;
  flex-direction: column;
  width:100%;
  margin-right: 1.5%;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
}

.divisionLabel{
  font-size: 8px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.bar {
  height: 10px;
  border-radius: 1px;
  margin-right: 1%;
  transition: background-color 1s ease-in-out;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.filled{
  background-color: $main-red-color;
}

.unfilled {
  background-color: #FF7A00;
  opacity: 0.05;
}
