@import "src/styles/colors";
@import "src/styles/themeVariables";

.dark{

  .profile-container__block {
    background: rgba(255, 255, 255, 0.10);
  }
  .profile-container{
    svg{
      path{
        stroke: $dark-theme-text;
      }
    }
  }

  .success-container__icon{
    svg{
      path{
        stroke: $dark-theme-text;
      }
    }
  }
  .success-container__header{
    svg{
      path{
        stroke: $dark-theme-text;
        fill: $dark-theme-text;
      }
    }
  }

  .success-container__subtitle{
    color: $dark-theme-text;
  }
  .profile-container__block__settings{
    &.log-out{
      svg{
        path{
          stroke: $dark-theme-bg-bar-3;
        }
      }
    }
  }


}

.profile-container{
  width: 100%;
  padding: 0 24px;
  .profile-container__title{
    font-family: 'Bebas Neue', sans-serif;
  }

  &__block{
    padding: 24px;
    border-radius: 16px;
    background-color: $light-gray;
    &__icon{
      svg{
        width: 40px;
        height: 40px;
      }
      width: 40px;
      height: 40px;
    }
    &__title{
      //color: $subtitle-color;
    }
    &__row-wrapper{
      row-gap: 8px;
      padding: 12px 0;
      border-bottom: 1px solid $light-theme;
      &__title{
        //color:$light-theme-text;
      }
      &__btn{
        color: $main-red-color;
      }
    }
    &__main-title{
      text-transform: uppercase;
      padding: 0 24px;
    }
    &__settings{
      padding: 16px 0;
      &__red-text{
        color: $main-red-color;
      }
    }
  }
}

.dark{
.profile-container__block__row-wrapper__btn{
  path{
    fill: $light-theme;
  }
}
  .languages-wrapper__content__option{
    background-color: $dark-theme-bg-bar !important;
  }
}
.languages-wrapper{
  height: 100dvh;
  padding: 24px;
  .languages-wrapper__content{
    max-height: calc(100dvh - 48px - 46px);
    height: 100%;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .languages-wrapper__content__options{
      gap: 8px;
      .languages-wrapper__content__option{
        padding: 12px 16px;
        border-radius: 8px;
        height: 52px;
        align-items: center;
        background-color: $light-gray;
      }
    }
  }
}
.change-password-wrapper{
  height: 100dvh;
  padding: 24px;
  .contentContainer{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  &.success-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .success-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
  }
}

.btn-change-password{
  position: sticky;
  bottom: 104px
}


.success-container__subtitle{
  color: $input-border-color;
}

.subscription{
  .languages-wrapper__content{
    align-items: center;
  }
  .success-container__active-since{
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    min-width: 327px;
    background-color: $main-white-color;
    color: $main-black-color;
  }
}