@use "sass:map";
@import "./styles/sizes";
@import "./styles/colors";
@import "styles/fonts-weights";
@import './styles/themeVariables';
@import './media';

.app {
  height: 100%;
  min-height: 100vh;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.light{
    background: map.get($lightTheme, "colorBackground");
    text-decoration-color: map.get($lightTheme, "colorText") ;
    color: map.get($lightTheme, "colorText") ;
  }

  &.dark{
    background: map.get($darkTheme, "colorBackground");
    text-decoration-color: map.get($darkTheme, "colorText") ;
    color: map.get($darkTheme, "colorText") ;
  }
}

a {
color: $main-red-color;
}

.red-color{
  color: $main-red-color;
}
.common-color{
  color: $main-black-color;
}
.dark{
  .common-color{
    color: $main-white-color;
  }
}

.appContainer{
  border-radius: $default-border-radius;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin:auto;
  box-shadow: 0 0 25px 0 #0000001A;
  z-index: 1;

  width: $small-desktop-app-container-width;
  padding: 25px $small-desktop-app-container-side-padding;

  &.app{
    justify-content: flex-start;
    padding: 0px;
  }

  &.light{
    background: map.get($lightTheme, "colorContainerBackground");
  }

  &.dark{
    background: map.get($darkTheme, "colorContainerBackground");
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .appContainer {
    min-width: $mobile-app-container-width;
    width: auto;
    padding: 25px $small-desktop-app-container-side-padding;
  }
}

.row-container{
  display: flex;
  flex-direction: row;
}
.font-bebas{
  font-family: 'Bebas Neue', sans-serif;
}
.column{
  display: flex;
  flex-direction: column;
}
.align-center{
  display: flex;
  align-items: center;
}
.align-end{
  display: flex;
  align-items: flex-end;
}
.space-between{
  display: flex;
  justify-content: space-between;
}
.base-line{
  display: flex;
  align-items: baseline;
}
.justify-center{
  display: flex;
  justify-content: center;
}
.typography-40{
  font-size: 40px;
  line-height: 48px;
}
.typography-34{
  font-size: 34px;
  line-height: 40px;
}
.typography-42{
  font-size: 42px;
  line-height: 52px;
}
.typography-32{
  font-size: 32px;
  line-height: 120%;
}
.typography-30{
  font-size: 30px;
  line-height: normal;
}
.typography-28{
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.typography-26{
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}
.typography-24{
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.typography-22{
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}

.typography-bold{
  font-weight: 700 !important;
}
.typography-500{
  font-weight: 500 !important;
}
.typography-300{
  font-weight: 300;
}
.typography-400{
  font-weight: 400 !important;
}
.typography-600{
  font-weight: 600 !important;
}
.typography-18{
  font-size: 18px;
  line-height: normal;
}
.typography-44{
  font-size: 44px;
  line-height: 52px;
}
.typography-46{
  font-size: 44px;
  line-height: 55px;
}
.typography-17{
  font-size: 17px;
  line-height: 21px;
}
.typography-20{
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
.typography-14{
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}
.typography-16{
  font-size: 16px;
  line-height: 20px;
}
.typography-8{
  font-size: 8px;
  line-height: normal;
}
.typography-12{
  font-size: 12px;
  line-height: 15px;
}
.typography-10{
  font-size: 10px;
  line-height: 18px;
}
.mt-40{
  margin-top: 40px;
}
.mt-32{
  margin-top: 32px;
}
.mt-24{
  margin-top: 24px;
}
.mt-20{
  margin-top: 20px;
}
.mt-16{
  margin-top: 16px;
}
.mt-12{
  margin-top: 12px;
}
.mt-8{
  margin-top: 8px;
}
.mr-12{
  margin-right: 12px;
}
@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
 .appContainer {
   width: $small-desktop-app-container-width;
   padding: 25px $small-desktop-app-container-side-padding;
 }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .appContainer {
    width: $desktop-app-container-width;
    padding: $desktop-app-container-side-padding;
  }
}
.app-wrapper{
  padding-bottom: 106px !important;
}

.ReactModal__Overlay{
  z-index: 9999;
}
.text-center{
  text-align: center;
}