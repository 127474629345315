@use "sass:map";
@import "/src/styles/sizes.scss";
@import "styles/fonts-weights";

* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: 'Bebas Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
