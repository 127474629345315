@import "../../../styles/sizes";
@import '../../../media';

.enticingOneQuestion {
  text-align: left;
  text-transform: uppercase;
  line-height: normal;
  font-size: 22px;
  margin-top: $small-desktop-default-gap;
}

.enticingOneAgreeButton {
  margin-top: $small-desktop-default-gap;
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height){
  .enticingOneQuestion {
    font-size: 16px;
    margin-top: $small-desktop-default-gap;
  }

  .enticingOneAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .enticingOneQuestion {
    margin-top: $small-desktop-default-gap;
  }

  .enticingOneAgreeButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .enticingOneQuestion {
    margin-top: $desktop-default-gap*2;
  }

  .enticingOneAgreeButton {
    margin-top: $desktop-default-gap;
  }
}
