@use "sass:map";
@import "../../../styles/sizes";
@import "../../../styles/colors";
@import "../../../styles/fonts-weights";
@import '../../../media';

.relationshipIssueQuestion {
  text-align: left;
  text-transform: uppercase;
  font-weight: map.get($font-weights,"title");
  margin-top: $small-desktop-default-gap;
}

.dark{
  .relationshipIssueOptionButton{
    .button{
      //color: $main-black-color !important;
    }
  }
}
.light{
  .relationshipIssueOptionButton{
    .button{
      //color: $main-white-color;
    }
  }
}

.relationshipIssueOptionButton {
  margin-top: $small-desktop-default-gap;
  .button{
    text-transform: uppercase;
    text-align: left;
  }

}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .relationshipIssueOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .relationshipIssueOptionButton {
    margin-top: $small-desktop-default-gap;
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .relationshipIssueQuestion {
    margin: $desktop-default-gap 0;
  }

  .relationshipIssueOptionButton {
    margin-top: $desktop-default-gap;
  }
}
