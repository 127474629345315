@import "../../styles/colors";
@import '../../media';

.carouselWrapper {
  margin-top: $small-desktop-default-gap*2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .alice-carousel__dots {
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 18px;

    margin: 40px auto 0;

    &>li {
      width:100%;
      margin-right:5px;
    }
  }

  .alice-carousel {
    position: relative;

    .alice-carousel__prev-btn {
      position:absolute;
      text-align: unset;
      bottom: 0;
      left: -15px;
    }

    .alice-carousel__next-btn {
      position:absolute;
      bottom:0;
      right: -15px;
    }
  }

  .carouselLeftArrow, .carouselRightArrow {
  cursor: pointer;
  padding: 6px;
  width: 20px;
  height: 26px;
  }

  .appPreviewContainer {
    .alice-carousel__stage-item {
      & > img {
        width: 150px;
        position: relative;
        top: 25px;
      }
    }

    .alice-carousel__stage-item.__active {
      & > img {
        width: 174px;
        top: 0;
      }
    }
  }

  .reviewsContainer {
    .alice-carousel__stage-item {
        width: 280px;
    }
  }
}

@media only screen and (max-width: $smartphone-width) and (max-height: $smartphone-height) {
  .carouselWrapper {
    margin-top: $small-desktop-default-gap*2;

    .alice-carousel__dots {
      width: 50%;
      bottom: 18px;
    }

    .carouselLeftArrow, .carouselRightArrow {
      width: 20px;
      height: 26px;
    }
  }

  .appPreviewContainer {
    .alice-carousel__stage-item {
      & > img {
        width: 150px;
        position: relative;
        top: 25px;
      }
    }

    .alice-carousel__stage-item.__active {
      & > img {
        width: 174px;
        top: 0;
      }
    }
  }

  .reviewsContainer {
    .alice-carousel__stage-item {
      width: 260px ;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $small-desktop-min-height) {
  .carouselWrapper {
    margin-top: $small-desktop-default-gap*2;

    .alice-carousel__dots {
      width: 50%;
      bottom: 18px;
    }

    .carouselLeftArrow, .carouselRightArrow {
      width: 20px;
      height: 26px;
    }
  }

  .appPreviewContainer {
    .alice-carousel__stage-item {
      & > img {
        width: 150px;
        position: relative;
        top: 25px;
      }
    }

    .alice-carousel__stage-item.__active {
      & > img {
        width: 174px;
        top: 0;
      }
    }
  }

  .reviewsContainer {
    .alice-carousel__stage-item {
      width: 280px;
    }
  }
}

@media only screen and (min-width: $small-desktop-min-width) and (min-height: $desktop-min-height) {
  .carouselWrapper {
    margin-top: $desktop-default-gap*2;

    .alice-carousel__dots {
      width: 50%;
      bottom: 18px;
    }

    .carouselLeftArrow, .carouselRightArrow {
      width: 20px;
      height: 26px;
    }
  }

  .appPreviewContainer {
    .alice-carousel__stage-item {
      & > img {
        width: 188px;
        position: relative;
        top: 20px;
      }
    }

    .alice-carousel__stage-item.__active {
      & > img {
        width: 208px;
        top: 0;
      }
    }
  }

  .reviewsContainer {
    .alice-carousel__stage-item {
      width: 335px ;
    }
  }
}
.carousel-left-arrow{
  path{
    stroke: $main-red-color;
  }
}
.carousel-right-arrow{
  path{
    stroke: $main-red-color;
  }
  transform: rotateY(180deg);
}